import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
// @ts-expect-error Remix bug: https://github.com/remix-run/remix/issues/9605
import { AdapterDateFns } from "@mui/x-date-pickers/node/AdapterDateFnsV3";
import { de } from "date-fns/locale/de";
import type { ReactNode } from "react";

import { theme } from "~/styles/theme.ts";

function createEmotionCache() {
	return createCache({ key: "css" });
}

export function MuiProvider({ children }: { children: ReactNode }) {
	const cache = createEmotionCache();
	return (
		<LocalizationProvider
			dateAdapter={AdapterDateFns as never}
			adapterLocale={de}
		>
			<CacheProvider value={cache}>
				<ThemeProvider theme={theme}>{children}</ThemeProvider>
			</CacheProvider>
		</LocalizationProvider>
	);
}
